var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        persistent: "",
        "hide-overlay": "",
        "max-width": "500px",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "v-icon",
                { attrs: { left: "", size: "40", color: "black" } },
                [_vm._v(_vm._s(_vm.options.icon))]
              ),
              _vm._v(" " + _vm._s(_vm.options.title) + " "),
            ],
            1
          ),
          _c("v-card-text", {
            staticClass: "black--text",
            domProps: { innerHTML: _vm._s(_vm.options.message) },
          }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize",
                  attrs: { width: "100" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize ml-2",
                  attrs: { width: "100", color: "success" },
                  on: { click: _vm.confirm },
                },
                [_vm._v(" Confirm ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }