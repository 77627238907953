<template>
  <v-dialog :value="value" persistent hide-overlay max-width="500px">
    <v-card>
      <v-card-title class="headline">
        <v-icon left size="40" color="black">{{ options.icon }}</v-icon>
        {{ options.title }}
      </v-card-title>
      <v-card-text v-html="options.message" class="black--text" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          width="100"
          class="text-capitalize"
          @click="$emit('update:value', false)">
          Cancel
        </v-btn>
        <v-btn
          width="100"
          color="success"
          class="text-capitalize ml-2"
          @click="confirm">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    value: Boolean,
    options: Object
  },
  methods: {
    confirm() {
      this.$emit('confirm', {
        method: this.options.event,
        data: this.options.data
      })
      this.$emit('update:value', false)
    }
  }
}
</script>
